import { getToken } from "utils/LocalStorage";
import axios from "axios";
import {API_GO_BY_URL} from "../config/constants";
import Profile from "models/Profile";
import City from "models/City";
import { Url } from "url";
import { DocumentsProfile } from "scenes/SingUp/models/DocumentsProfile";

const AuthService = {
    verifyUserSession: async (): Promise<any> => {
        //Todo: check
        const response = getToken();
        // if (!store.getState().user.token) {
        //     return {
        //         success: false,
        //         error: "Don't have a token"
        //     };
        // }
        if (!response?.token) {
            return {
                success: false,
                error: "Don't have a token"
            };
        }
        try {
            const currentUser : any = await AuthService.getUser(response?.token);
            const userData = response;
            let user: any | null = null;
            if (userData) {
                let profile: Profile | undefined = undefined;

                if (currentUser.status === 200) {

                    let city: City | undefined = undefined;
                    const currentProfile = currentUser.data.data.profile;
                    let document: DocumentsProfile | undefined = undefined;
                    if (currentProfile?.city) {
                        city = {
                            id: currentProfile?.city.id,
                            regionId: currentProfile?.city.regionId,
                            regionName: currentProfile?.city.regionName,
                            provinceId: currentProfile?.city.provinceId,
                            provinceName: currentProfile?.city.provinceName,
                            cityId: currentProfile?.city.cityId,
                            cityName: currentProfile?.city.cityName
                        };

                        profile = {
                            id: currentProfile?.id,
                            name: currentProfile?.name,
                            lastname: currentProfile?.lastname,
                            validatedFullname: currentProfile?.validatedFullname,
                            documentType: currentProfile?.documentType,
                            documentNumber: currentProfile?.documentNumber,
                            gender: currentProfile?.gender,
                            phone: currentProfile?.phone,
                            birthDate: currentProfile?.birthDate,
                            dniFrontal:currentProfile?.dniFrontal,
                            dniReverse:currentProfile?.dniReverse,
                            pensionary:currentProfile?.pensionary,
                            nationality: currentProfile?.nationality,
                            validatedInformationAt: currentProfile?.validatedInformationAt,
                            city: city,
                            address: currentProfile?.address,
                            maritalStatus: currentProfile?.maritalStatus,
                            number:currentProfile?.number,
                            pensionarySystem:currentProfile?.pensionarySystem,
                            typeHome:currentProfile?.typeHome,
                            aditionalDataSS:currentProfile?.aditionalDataSS,
                            metamapStatus: userData?.profile?.metamapStatus,
                            aditionalDataCv: currentProfile?.currentProfile,
                            dataIncomplete: (currentProfile?.validatedBankDataAt &&
                              currentProfile?.validatedPersonalInformationAt &&
                              currentProfile?.validatedUploadedDocumentationAt)
                        }
                    }
                }
                user = {
                    id: userData.id,
                    email: userData.email,
                    roleId: userData.roleId,
                    countryCode: userData.countryCode,
                    token: userData.token,
                    hubspotVisitorToken: userData.hubspotVisitorToken,
                    avatarBase64Encoded: currentUser?.data?.data?.avatarS3Key,
                    profile: profile,
                };
            }
            return {
                success: true,
                data: user,
            };
        } catch (err) {
            return {
              sucess: false
            }
        }
    },
    Logout: async(token: string) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            return await axios.post(`${API_GO_BY_URL}/auth/logout`);
          } catch (error) {
            return Promise.reject('Catch ' + JSON.stringify(error));
          }
    },
    getCurrentProfile: async (responses:any): Promise<any> => {
      try {
          const response = await AuthService.getProfile(responses?.token);
          const profileData = response.data.data;
          let profile: Profile | null = null;

          if(profileData) {
              let city: City | undefined = undefined;

              if(profileData.city) {
                  city = {
                      id: profileData.city.id,
                      regionId: profileData.city.regionId,
                      regionName: profileData.city.regionName,
                      provinceId: profileData.city.provinceId,
                      provinceName: profileData.city.provinceName,
                      cityId: profileData.city.cityId,
                      cityName: profileData.city.cityName
                  };

                  profile = {
                      id: profileData.id,
                      name: profileData.name,
                      lastname: profileData.lastname,
                      validatedFullname: profileData.validatedFullname,
                      documentType: profileData.documentType,
                      documentNumber: profileData.documentNumber,
                      dniFrontal:profileData?.dniFrontal,
                      dniReverse:profileData?.dniReverse,
                      pensionary:profileData?.pensionary,
                      gender: profileData.gender,
                      phone: profileData.phone,
                      birthDate: profileData.birthDate,
                      nationality: profileData.nationality,
                      validatedInformationAt: profileData.validatedInformationAt,
                      city: city,
                      address: profileData.address,
                      maritalStatus: profileData?.maritalStatus,
                      number:profileData?.number,
                      pensionarySystem:profileData?.pensionarySystem,
                      typeHome:profileData?.typeHome,
                      aditionalDataSS:profileData?.aditionalDataSS,
                      metamapStatus: profileData?.profile?.metamapStatus,
                      aditionalDataCv: profileData?.currentProfile,
                      dataIncomplete: (profileData?.validatedBankDataAt &&
                        profileData?.validatedPersonalInformationAt &&
                        profileData?.validatedUploadedDocumentationAt)
                  }
              }
          }
          return {
            success: true,
            data: profile,
        };
      }catch (err)
      {
        return {
          sucess: false
        }
      }
  },
    getStatesProfile: async (token: string) => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const result = await axios.get(`${API_GO_BY_URL}/profile/go-by-touch/information`);
        return result?.data?.data;
      } catch (error) {
        return Promise.reject('Catch ' + JSON.stringify(error));
      }
    },
    getProfile: async(token: string) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            return await axios.get(`${API_GO_BY_URL}/profile/go-by-touch/current-user-profile`);
          } catch (error) {
            return Promise.reject('Catch ' + JSON.stringify(error));
          }
    },
    skipOboarding: (token: string) => {
        try {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          axios.defaults.headers.common['Content-Type'] = `multipart/form-data`;
          return axios.put(
            `${API_GO_BY_URL}/profile/go-by-touch/skip`
          );
        } catch (error) {
          return Promise.reject('Catch ' + JSON.stringify(error));
        }
      },      
    getUser: async(token: string) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            return await axios.get(`${API_GO_BY_URL}/auth/goer-current-user`);
          } catch (error) {
            return Promise.reject('Catch ' + JSON.stringify(error));
          }
    },
    //El valor png determina si la imagen que es transformada es svg o otro formato de imagen
    getImage: async(token: string, body: any, png?:boolean) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            const data =  await axios.post(`${API_GO_BY_URL}/file/`,body);
            if(png){
                return `data:image/jpeg;base64,${data.data.data.base64Encoded}`;
            }
            return `data:image/svg+xml;base64,${data.data.data.base64Encoded}`; 
          } catch (error) {
            return Promise.reject('Catch ' + JSON.stringify(error));
          }
    },
    //Este metodo devuelve un archivo en formato base64
    getFile: async(token: string, body: any) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            const data =  await axios.post(`${API_GO_BY_URL}/file/`,body);
            return `${data.data.data.base64Encoded}`; 
          } catch (error) {
            return Promise.reject('Catch ' + JSON.stringify(error));
          }
    },
    getFileFromUrl: async(url:string)=>{
      try {
        let base64:any;
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            base64=reader.result
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
        return base64;
      } catch (error) {
        return Promise.reject('Catch ' + JSON.stringify(error));
      }
      
    },
};

export default AuthService;