/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import Routes from './routes/routes';
import { useDispatch } from "redux-react-hook";
import AuthService from "./services/AuthService";
import UserAction from "./redux/reducers/user/actions";
import HelperAction from "./redux/reducers/helper/actions";
import * as dotenv from 'dotenv';
import 'moment/locale/es';
import 'react-simple-hook-modal/dist/styles.css';
import './assets/styles/global.scss';
import './App.scss';
import { getItem } from 'utils/LocalStorage';
import { useHistory } from 'react-router';


declare global {
	interface Window {
		_hsq: { push: (callParam: any) => void },
		hsConversationsSettings: any
	}
}

const App = () => {
	const store = useStore();
	const history = useHistory();
	const dispatch = useDispatch();
	const token = getItem('token');
	const country:any = getItem('country');
	const [loadingInitValues, setLoadingInitValues] = useState(true);
	const authUser = useSelector((state: any) => state.user);
	const { localCountry } = useSelector((state: any) => state.helperReducer);
	dotenv.config({ path: '../.env' });
	useEffect(() => { init(); }, [dispatch, localCountry, store, token]);

	const verifyUserSession = async () => {
		const user = await AuthService.verifyUserSession();
		if (user.success) {
			dispatch(UserAction.save(user.data));
		} else {
			dispatch(UserAction.remove());
			//window.location.reload();
		}
	}

	const init = async () => {
		await verifyUserSession();
		setLoadingInitValues(false);
	};

	return (
		<>
			{
				!loadingInitValues ? <Routes auth={authUser} /> : <></>
			}
		</>
	);
};

export default App;
